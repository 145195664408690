import React from 'react'

import Layout from '../../components/Layout'
import Water from '../../components/Water'

export default function WaterPage() {
  return (
    <Layout>
      <Water />
    </Layout>
  )
}
