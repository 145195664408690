import React, { useState } from 'react'
import { useMeasure } from 'react-use'
import tw, { theme } from 'twin.macro'

import OverviewHeader from '../OverviewHeader'
import CockpitList from '../CockpitList'
import BarStackChart from '../BarStackChart'
import ChartToggle from '../ChartToggle'
import DownloadCard from '../DownloadCard'
import useData from '../../data/useData'

const CHART_BOTTOM_OFFSET = 40

const EMISSION = 'CO2e kg/m3'

function getWaterConsumptionData(water) {
  const data = water.map((d) => {
    const consumption = d.m3
    return {
      date: d.year,
      consumption,
      emission: (d[EMISSION] * consumption) / 1000,
      location: d.site,
    }
  })

  return data
}

const getTooltipFormat = (toggleY) => (toggleY ? '.3s' : '.1f')
const getTickFormat = (toggleY) => (toggleY ? '~s' : '')
const getYProp = (toggleY) => (toggleY ? 'consumption' : 'emission')

const accessors = {
  x: (d) => d.date,
  z: (d) => d.location,
}

export default function Water() {
  const { water } = useData()
  const [c1Ref, { width: c1Width, height: c1Height }] = useMeasure()
  const [toggleY, setToggleY] = useState(true)

  return (
    <div tw="flex-1 lg:grid lg:grid-cols-4 lg:overflow-x-auto">
      <CockpitList parentStyles={[tw`col-span-3`]} tw="overflow-x-hidden">
        <div
          css={[
            `height: calc(100vh - ${theme`spacing.20`});`,
            tw`flex flex-col`,
          ]}
        >
          <OverviewHeader title={<>Water consumption ({unit(toggleY)})</>} />
          <div tw="flex-1 h-0" ref={c1Ref}>
            {c1Height && (
              <BarStackChart
                width={c1Width}
                height={c1Height - CHART_BOTTOM_OFFSET}
                data={getWaterConsumptionData(water)}
                accessors={accessors}
                unit={unit(toggleY)}
                xProp="date"
                yProp={getYProp(toggleY)}
                toggle={
                  <ChartToggle
                    selected={toggleY}
                    onChange={() => setToggleY((prev) => !prev)}
                    rightLabel="Consumption"
                    leftLabel="Emission"
                  />
                }
                tooltipFormat={getTooltipFormat(toggleY)}
                tickFormat={getTickFormat(toggleY)}
                increaseDomainByValue={1.1}
                tw="px-3 pt-4 pb-3"
              />
            )}
          </div>
        </div>
      </CockpitList>

      <CockpitList tw="bg-grey-6">
        <OverviewHeader title="EXPLORE" />
        <DownloadCard field="water" title="Water" />
      </CockpitList>
    </div>
  )
}

function unit(toggleY) {
  return toggleY ? 'm³' : 'tCO₂e'
}
